import React from 'react'
import { Box, Typography } from '@mui/material'

const AtsHome = () => {
  return (
    <Box>
      <Typography variant='h1'>ATS Homepage</Typography>
    </Box>
  )
}
export default AtsHome
